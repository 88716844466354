var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main",style:({
    background: ("url(" + (require('@/assets/img/bg.jpg')) + ")"),
    minHeight: '100vh',
    'background-size': 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })},[_c('div',{staticClass:"container"},[_c('div',[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"card"}),_c('div',{staticClass:"card"},[_vm._m(1),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('div',{staticClass:"input-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.username),expression:"form.username"}],ref:"usernameInput",attrs:{"required":"","type":"text"},domProps:{"value":(_vm.form.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "username", $event.target.value)}}}),_c('label',{attrs:{"for":"Username"}},[_vm._v("Пользователь")]),_c('div',{ref:"username",staticClass:"bar error-text"})]),_c('div',{staticClass:"input-container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],attrs:{"autocomplete":"on","required":"","type":"password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('label',{attrs:{"for":"Password"}},[_vm._v("Пароль")]),_c('div',{ref:"password",staticClass:"bar error-text"})]),_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"login-btn",staticStyle:{"background-color":"rgb(229, 2, 62)","min-height":"70px"},attrs:{"disabled":_vm.loading}},[_c('span',{staticStyle:{"color":"rgb(255, 255, 255)"}},[_vm._v(_vm._s(_vm.loading ? "Загрузка..." : "Вход в систему"))])])])])]),_vm._m(2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{attrs:{"href":"https://osg.uz/","title":"Online Service Group"}},[_c('img',{attrs:{"src":require("@/assets/img/logo.png")}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"title"},[_c('span',[_vm._v("Вход в панель")]),_vm._v(" OSG CMS")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"copy"},[_c('a',{attrs:{"href":"https://osg.uz/","title":"Online Service Group"}},[_c('p',[_vm._v("OOO \"Online Service Group\"")])]),_c('p',[_vm._v("© 2022. Все права защищены.")])])}]

export { render, staticRenderFns }